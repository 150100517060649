<template>
  <div
    v-editable="blok"
    class="TextImageVideoBlock"
    :class="desktopWidth('md')"
  >
    <div
      :key="'videoKey' + videoKey"
      class="flex flex-col desk:flex-row"
      :class="{
        'desk:flex-row-reverse': blok.leftAlignedContent,
        'bg-darkGreen': blok.colorTheme === 'green',
        'bg-lighter': blok.colorTheme === 'grey',
      }"
    >
      <div
        ref="imageContainer"
        class="flex flex-row grow-0 shrink-0 basis-2/4 gap-8 desk:gap-12"
        :class="{
          'basis-4/6': blok.enableAdditionalImage,
        }"
      >
        <div
          class="flex-1"
          :class="{
            [aspectRatio]: aspectRatio,
          }"
        >
          <video
            v-if="checkIfVideoFile(blok.imageOrBackgroundVideo.filename) && !hideVideo"
            :width="aspectRatio === '' ? 'w-auto' : 'w-full'"
            :height="aspectRatio === '' ? 'h-auto' : 'h-full'"
            class="w-full h-full object-cover"
            loop
            muted
            autoplay
            playsinline
          >
            <source :src="blok.imageOrBackgroundVideo.filename + `?videoKey=${videoKey}`" type="video/mp4">
          </video>
          <nuxt-img
            v-else-if="blok.imageOrBackgroundVideo && blok.imageOrBackgroundVideo.filename && !hideVideo"
            preset="standard"
            loading="lazy"
            sizes="sm:100vw desk:1260px"
            class=""
            :class="aspectRatio === '' ? 'w-auto h-auto' : 'w-full h-full object-cover'"
            :src="blok.imageOrBackgroundVideo.filename"
            :alt="blok.imageOrBackgroundVideo.alt"
          />
        </div>
        <div
          v-if="blok.additionalImage && props.blok.enableAdditionalImage"
          class="flex-1"
          :class="{
            [aspectRatio]: aspectRatio,
          }"
        >
          <nuxt-img
            preset="standard"
            loading="lazy"
            sizes="sm:100vw desk:1260px"
            :class="aspectRatio === '' ? 'w-auto h-auto' : 'w-full h-full object-cover'"
            :src="blok.additionalImage.filename"
            :alt="blok.additionalImage.alt"
          />
        </div>
      </div>
      <div
        class="flex grox-0 shrink-0 basis-2/4 justify-center items-center px-12 py-32 desk:px-64"
        :class="{
          'basis-2/6 desk:px-32': blok.enableAdditionalImage,
        }"
      >
        <div
          class="flex flex-col"
          :class="{
            'items-center text-center': !blok.textAlign || blok.textAlign === 'center',
            'items-start': blok.textAlign === 'left',
            'items-end': blok.textAlign === 'right',
            'text-brightGreen' : blok.colorTheme === 'green',
          }"
        >
          <div
            class="type-headline-sm underline decoration-[#C7C6C2] mb-12"
            :class="{'decoration-brightGreen' : blok.colorTheme === 'green',}"
          >
            {{ blok.tagline }}
          </div>
          <div
            class="type-headline-lg desk:type-headline-3xl mb-4"
          >
            {{ blok.headline }}
          </div>
          <div class="wysiwyg mb-16">
            <ExtendedWysiwyg :content="blok.content" />
          </div>
          <nuxt-link
            v-if="blok.buttonText"
            :to="$sbLinkFix(blok.link)"
            :target="$sbLinkTarget(blok.link)"
          >
            <div
              class="btn btn--primary"
              :class="{
                'btn--confirm' : blok.colorTheme === 'green',
              }"
            >
              {{ blok.buttonText }}
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  StoryBlokPropBase,
  StoryBlokAsset,
  StoryBlokVisualApperance, StoryBlokLink
} from '~/constants/types/storyblok';

import { checkIfVideoFile } from '~/util/imageUtil';
import { AspectRatioKeys } from '~/constants/aspectRatioKeys';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import { ISbRichtext } from 'storyblok-js-client';

type Props = StoryBlokPropBase & {
  additionalImage: StoryBlokAsset;
  enableAdditionalImage: boolean;
  tagline: string;
  headline: string;
  content: ISbRichtext;
  buttonText: string;
  imageAspectRatio: AspectRatioKeys;
  imageOrBackgroundVideo: StoryBlokAsset;
  leftAlignedContent: boolean;
  textAlign?: 'left' | 'center' | 'right';
  visualAppearance: StoryBlokVisualApperance;
  link: StoryBlokLink;
  colorTheme: '' | 'default' | 'grey' | 'green',
}

const props = defineProps<{
  blok: Props,
}>();

const { desktopWidth } = useDesktopWidth(props);

const route = useRoute();
const hideVideo = ref(false);
const videoKey = ref(1);
const isInStoryblokAdmin = (route.query && route.query._storyblok && route.query._storyblok !== '');

/**
 * This fixes a thing in storyblok admin where videos refused to change size
 */
watch(() => props.blok.imageAspectRatio, ()=> {
  if (isInStoryblokAdmin) {
    hideVideo.value = true;
    nextTick(()=> {
      videoKey.value++;
      setTimeout(()=> {
        hideVideo.value = false;
      }, 250);
    });
  }
});

const aspectRatio = computed<string>(() => {
  return props.blok.imageAspectRatio === 'aspect-original' ? '' : props.blok.imageAspectRatio;
});

</script>
<style scoped lang="postcss">

</style>
